import { ClientInterface, createClient } from './api/createClient'

export const PaymentsConfig = ({ headers }) => {
  const baseURL = String(process.env.REACT_APP_API_ADDRESS)
  const client = createClient({ baseURL: `${baseURL}/payments-config`, headers })

  return {
    getClient(): ClientInterface {
      return client
    },

    async getListProcessingCodes() {
      return await client.get(`/v1/processing-codes`)
    },
  }
}
